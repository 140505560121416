import { Injectable } from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppStorageService } from "../services/app-storage.service";
import { tap } from "rxjs/operators";
import { MessageHandlerService } from "../services/message-handler.service";
import {MessageTypeEnum} from "../../enums/message-type.enum";
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';

/** Inject With Credentials into the request */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  private ignoredListCodes = [10004, 10022, 11200];

  constructor(private storage: AppStorageService,
              private errorService: MessageHandlerService,
              private authService: AuthService,
              private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
      // if (this.storage.getItemFromLocalStorage(StorageEnum.LOGIN_TOKEN)) {
      //   req = req.clone({
      //     headers: req.headers.append('Authorization', `Bearer ${this.storage.getItemFromLocalStorage(StorageEnum.LOGIN_TOKEN)}`)
      //   });
      // }
      return next.handle(req).pipe(
        tap(
          (event: any) => {
            const ok = event instanceof HttpResponse ? 'succeeded' : '';
            if (ok === 'succeeded') {
              if (event.body.errors && event.body.errors.length) {
                if (event.body && event.body.errors &&  event.body.errors[0]) {
                  if (this.ignoredListCodes.indexOf(
                      event.body.errors[0]['code']) === -1
                  ) {
                    this.errorService.openDialog({
                      type: MessageTypeEnum.ERROR,
                      explanation: this.errorCodeMessage(event.body.errors[0]['code']) || event.body.errors[0].explanation,
                      message: this.errorCodeMessage(event.body.errors[0]['code']) || event.body.errors[0].message
                    }, () => {
                      if (event.body.errors[0]['code'] === 10003) {
                        this.authService.logout(() => {
                          this.router.navigate(['login']);
                        });
                      }
                    });
                  }
                }
                throw new HttpErrorResponse({
                  error: event.body.errors,
                  headers: event.headers,
                  status: 500,
                  statusText: event.body.errors[0]['message'],
                  url: event.url
                });
              }
            }
          },
          error => {
            if (error.status === 403) {
              this.authService.logout(() => {
                this.router.navigate(['login']);
              });
            }
          }
        )
      );
  }

  private errorCodeMessage(code) {
    switch (code) {
      case 10007: {
        return 'Username not found';
      }
      case 10000: {
        return 'Something went wrong. Please try again later';
      }
      case 10017: {
        return 'Invalid phone number or code';
      }
    }
  }

}
